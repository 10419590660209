import { render, staticRenderFns } from "./Forget.vue?vue&type=template&id=45f646ef&scoped=true&"
import script from "./Forget.vue?vue&type=script&lang=js&"
export * from "./Forget.vue?vue&type=script&lang=js&"
import style0 from "./Forget.vue?vue&type=style&index=0&id=45f646ef&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f646ef",
  null
  
)

export default component.exports