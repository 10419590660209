<template>
  <div class="login page">
    <Nav></Nav>
    <main class="wrap">
      <div class="login-box-wrap">
        <div class="login-box">
          <div class="title">密码找回</div>
          <a-form-model id="components-form-demo-normal-login" ref="ruleForm" :model="formData" :rules="rules"
            :label-col="labelCol" :wrapper-col="wrapperCol" class="login-form">
            <a-form-model-item ref="mobiel" label="手机号" prop="mobiel">
              <a-row :gutter="8">
                <a-col :span="15">
                  <a-input v-model="formData.mobiel" @blur="() => { $refs.mobiel.onFieldBlur(); }" placeholder="请输入手机号">
                  </a-input>
                </a-col>
                <a-col :span="7">
                  <a-button @click="sendViewCode" :disabled="updateDisableFlag">
                    <span v-if="updateDisableFlag">({{ settimer }})秒后重发</span>
                    <span v-else>获取验证码</span>
                  </a-button>
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item ref="codeChar" label="验证码" prop="codeChar">
              <a-input v-model="formData.codeChar" @blur="() => { $refs.codeChar.onFieldBlur(); }"
                placeholder="请输入收到的验证码">
              </a-input>
            </a-form-model-item>
            <a-form-model-item has-feedback label="密码" ref="pass" prop="pass">
              <a-input-password v-model="formData.pass" type="password" autocomplete="off" :loading="false"
                @blur="() => { $refs.pass.onFieldBlur(); }" placeholder="请输入密码" />
            </a-form-model-item>
            <a-form-model-item has-feedback label="确认密码" ref="checkPass" prop="checkPass">
              <a-input-password v-model="formData.checkPass" type="password" autocomplete="off" :loading="false"
                @blur="() => { $refs.checkPass.onFieldBlur(); }" placeholder="请输再次输入密码" />
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 15, offset: 5 }">
              <a-button type="primary" html-type="submit" class="login-form-button" @click="onSubmit">
                修改密码
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </div>
    </main>
    <footer>
      <Foot></Foot>
    </footer>
  </div>
</template>

<script>
import Foot from "@/components/foot.vue";
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    let mobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formData.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.formData.pass) {
        callback(new Error("2次密码输入不一致!"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      formData: {
        mobiel: '',
        codeChar: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        mobiel: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: mobile, trigger: "blur" },
        ],
        codeChar: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "change" },
        ],
        checkPass: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "change" },
        ],
      },
      updateDisableFlag: false,
      settimer: 60,
      shortId: 0,
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let that = this;

          if(!that.shortId){
            this.$message.error("请先获取验证码", 3);
            return;
          }

          this.api
            .vercodeChangePassword({
              mobile: that.formData.mobiel,
              password: that.formData.pass,
              vid: that.shortId,
              code: that.formData.codeChar,
            }).then((res) => {
              console.log(res);
              this.$message.success("修改成功", 3);
              this.$router.push('/Login');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    sendViewCode() {
      this.$refs["ruleForm"].validateField(['mobiel'], (err) => {
        if (!err) {
          this.updateDisableFlag = true;
          let that = this;

          //发送短信方法
          this.api
            .sendPassWordVercode({
              mobile: that.formData.mobiel,
            })
            .then((res) => {
              if (res.errCode == 0) {
                this.shortId = res.id;
                this.$message.success("发送成功", 3);
                //计时开始
                let timer = setInterval(function () {
                  that.updateDisableFlag = true
                  that.settimer--;
                  if (that.settimer == 0) {
                    clearInterval(timer)
                    that.updateDisableFlag = false
                    that.settimer = 60;
                  }
                }, 1000);
              } else {
                that.updateDisableFlag = false
                that.settimer = 60;
                return this.$message.error(res.errMsg, 3);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.user = res;
          this.storage.setItem("user", res);
          this.$router.push("/user");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f5f5;
}

main {

  //position: relative;
  .login-box-wrap {
    width: 460px;
    height: 480px;
    background: #e8e6ed;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    .login-box {
      background: #ffffff;
      width: 91%;
      height: 460px;
      border-radius: 10px;
      position: absolute;
      top: 10px;
      right: 20px;

      .title {
        padding-top: 30px;
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }

      .login-logo {
        text-align: center;
        margin-top: 10px;
      }

      #components-form-demo-normal-login {
        margin: 20px auto;
        width: 90%;

        .login-form {
          max-width: 400px;
        }

        .login-form-forgot {
          float: right;
        }

        .login-form-button {
          width: 100%;
        }
      }
    }

    .login-img {
      width: 480px;
      height: 440px;
      position: absolute;
      left: 26%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

footer {
  width: 100%;
  margin-top: 0;
  position: fixed;
  bottom: 0;
}
</style>